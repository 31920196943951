import React from 'react';
import {Helmet} from 'react-helmet';

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Tintash</title>
    </Helmet>
    <section className="scrum-banner d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="row ">
          <div className="col-12">
            <h1 className="latoBlack text-left text-white heading pt-5">Modified Scrum Model</h1>
          </div>
        </div>
      </div>
    </section>
    <section className="modified-scrum">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="heading mb-2">Why we use a modified Scrum Model</h1>
            <p className="description">
              We believe that since each client and project is different, it’s not possible to
              expect that the same method is going to result in success for every project.
            </p>
            <div className="panel-group process-section mb-2" id="accordion">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="text-left">
                    <div className="panel-title  expertise-heading">
                      We assess the situation of each client
                    </div>
                  </h4>
                </div>
                <div id="client">
                  <div className="panel-body step-2">
                    <div className="row">
                      <div className="col-12">
                        <p className="description">
                          In order to come up with the best approach for project management for a
                          project, we assess who the client is, what time availability they have,
                          what expertise can they bring to the table, and what their expectation is
                          with the delivery process - e.g. do they want to be more involved or less
                          involved. Once we have a better idea about these client and project
                          realities, we then build the process around these realities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="text-left">
                    <div className="panel-title collapsed expertise-heading">
                      How roles get assigned
                    </div>
                  </h4>
                </div>
                <div id="roles">
                  <div className="panel-body step-2">
                    <div className="row">
                      <div className="col-12">
                        <p className="description">
                          If we consider the aspect of expertise for example, if people at the
                          client’s end are experienced in Scrum, then they can have in-house product
                          owners and scrum masters. Sometimes, the client is the product owner
                          whereas the scrum master is at Tintash. In other situations, clients have
                          no previous experience with Scrum, and in these scenarios the Project
                          Manager has to play a hybrid role where he/she has to play a role in
                          product ownership and also help out the development team in implementing a
                          Scrum like framework.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="text-left">
                    <div className="panel-title  expertise-heading">
                      How we work around client involvement and availability
                    </div>
                  </h4>
                </div>
                <div id="availability">
                  <div className="panel-body step-2">
                    <div className="row">
                      <div className="col-12">
                        <p className="description">
                          In terms of time availability, not every client or people from his/her
                          team have the time to participate in daily standups, review meetings,
                          planning meetings and retrospectives. If the client or other members of
                          the team co-located with the client can participate in all of these events
                          then that’s great. However, if they can’t, then we work around these
                          constraints by making sure that we involve them as much as possible, while
                          making sure that these Scrum events do at least take place between team
                          members at Tintash to ensure that work progress is being checked daily,
                          working software is being reviewed after each scrum cycle, and that
                          effective planning is being done for the next cycle.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);
